import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {ThemeProvider} from 'styled-components';
import theme from './styles/theme';
import GlobalStyle from "./styles/GlobalStyle";
import {RecoilRoot} from 'recoil';
import reportWebVitals from './reportWebVitals';
import './index.css';
import axios from 'axios';
import {autoA11yTest} from "./services/autoA11yTest";
import {CookiesProvider} from "react-cookie";

const root = ReactDOM.createRoot(document.getElementById('root'));

axios.defaults.withCredentials = true;

// autoA11yTest()

root.render(
    <>
    <GlobalStyle/>
        <CookiesProvider>
            <ThemeProvider theme={theme}>
                <RecoilRoot>
                    <App />
                </RecoilRoot>
            </ThemeProvider>
        </CookiesProvider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
