import {Suspense, lazy, useEffect} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Nav from './components/Nav/Nav';
import UnderNav from "./components/UnderNav/UnderNav";
import Footer from './components/Footer/Footer';
import Loading from "./components/Loading";
import ScrollToTop from "./services/ScrollToTop";
import PrivateRoute from "./services/PrivateRoute";
import SignHandler from "./pages/Login/SignHandler";
import SkipNav from "./components/Nav/SkipNav";
import ShortcutsMenu from "./components/ShortcutsMenu/ShortcutsMenu";

function App() {

    const Main = lazy(() => import('./pages/Main/Main'));
    const Login = lazy(() => import('./pages/Login/Login'));
    const SignUp = lazy(() => import('./pages/SignUp/SignUp'));
    const FindId = lazy(() => import('./pages/FindId/FindId'))
    const FindPassword = lazy(() => import('./pages/FindPassword/FindPassword'))
    const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy/PrivacyPolicy'))
    const Faq = lazy(() => import('./pages/Faq/Faq'))
    const PaymentCase = lazy(() => import('./pages/PaymentCase/PaymentCase'))
    const Notice = lazy(() => import('./pages/Notice/Notice'))
    const NewPasswordComplete = lazy(() => import('./pages/FindPassword/NewPasswordComplete'))
    const NewPassword = lazy(() => import('./pages/FindPassword/NewPassword'))
    const FoundIdList = lazy(() => import("./pages/FindId/FoundIdList"))
    const SignUpInsurance = lazy(() => import('./pages/SignUpInsurance/SignUpInsurance'))
    const RenewInsurance = lazy(() => import('./pages/RenewInsurance/RenewInsurance'))
    const ClaimInsurance = lazy(() => import('./pages/ClaimInsurance/ClaimInsurance'))
    const InsuranceDetail = lazy(() => import('./pages/InsuranceDetail/InsuranceDetail'))
    const Post = lazy(() => import('./components/Post/Post'))
    const GoodsDetail = lazy(() => import('./pages/UsedDeal/GoodsDetail/GoodsDetail'))
    const RegisterGoods = lazy(() => import('./pages/UsedDeal/RegisterGoods/RegisterGoods'))
    const SalesDetails = lazy(() => import('./pages/UsedDeal/SalesDetails/SalesDetails'))
    const WishList = lazy(() => import('./pages/UsedDeal/WishList/WishList'))
    const MyPage = lazy(() => import('./pages/MyPage/MyPage'))
    const ChatRoom = lazy(() => import('./pages/UsedDeal/MessageBox/components/ChatRoom'))
    const SignUpInsuranceComplete = lazy(() => import('./pages/SignUpInsurance/components/SignUpInsuranceComplete'))
    const SignUpInsuranceFail = lazy(() => import('./pages/SignUpInsurance/components/SignUpInsuranceFail'))
    const PreparingForService = lazy(() => import('./pages/PreparingForService/PreparingForService'))
    const NotFound = lazy(() => import('./pages/NotFound/NotFound'))
    const AboutUs = lazy(() => import('./pages/AboutUs/AboutUs'))
    const SignUpComplete = lazy(() => import('./pages/SignUp/SignUpComplete'))
    const VirtualAccountIssuedComplete = lazy(() => import('./pages/SignUpInsurance/components/VirtualAccountIssuedComplete'))
    const InsuranceApplication = lazy(() => import('./pages/InsuranceApplications/InsuranceApplication'))
    const EnterMyPage = lazy(() => import('./pages/MyPage/EnterMyPage'))
    const UsedDeal = lazy(() => import('./pages/UsedDeal/UsedDeal'))
    const InsuranceNotice = lazy(() => import('./pages/InsuranceDetail/components/InsuranceNotice'))
    const NotCompensated = lazy(() => import('./pages/InsuranceDetail/components/NotCompensated'))
    // const CompensationInformation = lazy(() => import('./pages/SignUpTravelInsurance/components/CompensationInformation'))
    // const SignUpTravelInsurance = lazy(()=>import('./pages/SignUpTravelInsurance/SignUpTravelInsurance'))
    // const TravelFaq = lazy(()=>import('./pages/Faq/TravelFaq'))
    // const TravelNotice = lazy(()=>import('./pages/Notice/TravelNotice'))



    useEffect(() => {

        const setMobileHeight = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`)
        }

        window.addEventListener('resize', setMobileHeight);
        return () => window.removeEventListener('resize', setMobileHeight)

    }, [])



    useEffect(() => {
        if(window.location.pathname === '/insuranceList/all'){
            window.location.replace('/insurancedetail/30');
        }else if(window.location.pathname === '/usedDeal/main'){
            window.location.replace('/useddeal?menu=goodslist')
        }else if(window.location.pathname === '/claim'){
            window.location.replace('/mypage/insuranceadmin')
        }
    }, [window.location.pathname])

    return (
        <BrowserRouter>

            <Suspense fallback={<Loading/>}>
                {/*<SkipNav />*/}

                <ScrollToTop/>
                {/*<Nav/>*/}

                <Routes>
                    <Route element={<PrivateRoute authenticated={true}/>}>
                        <Route path={'/signupinsurance/:id'} element={<SignUpInsurance/>}/>
                        <Route path={'/renewinsurance/:id'} element={<RenewInsurance/>}/>
                        <Route path={'/claiminsurance/:id'} element={<ClaimInsurance/>}/>
                        <Route path={'/registergoods'} element={<RegisterGoods/>}/>
                        <Route path={'/salesdetails'} element={<SalesDetails/>}/>
                        <Route path={'/wishlist'} element={<WishList/>}/>
                        <Route path={'/mypage/:status'} element={<MyPage/>}/>
                        <Route path={'/mypage'} element={<EnterMyPage/>}/>
                        <Route path={'/chatbox/:id'} element={<ChatRoom/>}/>
                        {/*<Route path={'/signuptravelinsurance'} element={<SignUpTravelInsurance/>}/>*/}
                    </Route>
                    <Route path={'/newpassword'} element={<NewPassword/>}/>
                    <Route path={'/foundidlist'} element={<FoundIdList/>}/>
                    <Route path={'/'} element={<Main/>}/>
                    <Route path={'/login'} element={<Login/>}/>
                    <Route path={'/signup'} element={<SignUp/>}/>
                    <Route path={'/findid'} element={<FindId/>}/>
                    <Route path={'/findpassword'} element={<FindPassword/>}/>
                    <Route path={'/privacypolicy'} element={<PrivacyPolicy/>}/>
                    <Route path={'/faq'} element={<Faq/>}/>
                    <Route path={'/paymentcase'} element={<PaymentCase/>}/>
                    <Route path={'/notice'} element={<Notice/>}/>
                    <Route path={'/paymentcase/:id'} element={<Post/>}/>
                    <Route path={'/faq/:id'} element={<Post/>}/>
                    <Route path={'/notice/:id'} element={<Post/>}/>
                    <Route path={'/aboutus'} element={<AboutUs/>}/>
                    <Route path={'/newpassword'} element={<NewPassword/>}/>
                    <Route path={'/insurancedetail/:id'} element={<InsuranceDetail/>}/>
                    {/*<Route path={'/insuranceList/all'} element={<InsuranceDetail/>}/>*/}
                    <Route path={'/useddeal'} element={<UsedDeal/>}/>
                    <Route path={'/goodsdetail/:id'} element={<GoodsDetail/>}/>
                    <Route path={'*'} element={<NotFound/>}/>
                    <Route path={'/login/kakao'} element={<SignHandler/>}/>
                    <Route path={'/signupinsurancecomplete'} element={<SignUpInsuranceComplete/>}/>
                    <Route path={'/signupinsurancefail'} element={<SignUpInsuranceFail/>}/>
                    {/*<Route path={'/signuptravelinsurancecomplete'} element={<SignUpInsuranceComplete/>}/>*/}
                    {/*<Route path={'/signuptravelinsurancefail'} element={<SignUpInsuranceFail/>}/>*/}
                    <Route path={'/prepairingforservice'} element={<PreparingForService/>}/>
                    <Route path={'/newpasswordcomplete'} element={<NewPasswordComplete/>}/>
                    <Route path={'/signupcomplete'} element={<SignUpComplete/>}/>
                    <Route path='/virtualaccountissuedcomplete' element={<VirtualAccountIssuedComplete/>}/>
                    <Route path={'/insuranceapplication'} element={<InsuranceApplication/>}/>
                    {/*<Route path={'/insurancenotice'} element={<InsuranceNotice/>}/>*/}
                    {/*<Route path={'/notcompensated'} element={<NotCompensated/>}/>*/}
                    <Route path={'/mypage'} element={<EnterMyPage/>}/>
                    {/*<Route path={'/travelfaq'} element={<TravelFaq/>}/>*/}
                    {/*<Route path={'/travelnotice'} element={<TravelNotice/>}/>*/}
                    {/*<Route path={'/compensationinformation'} element={<CompensationInformation/>}/>*/}

                    {/*<Route path={'/entersignuptravelinsurance'} element={<EnterSignUpTravelInsurance/>}/>*/}

                </Routes>

                <ShortcutsMenu />
                        <UnderNav/>
                <Footer/>



            </Suspense>
        </BrowserRouter>
    );
}

export default App;
